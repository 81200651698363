.download-human-log-alert{
    color: white;
}
a.download-human-log-alert:hover{
    color: white;
}
.download-human-log-no-alert{
    color: black;
}
a.download-human-log-no-alert{
    color: black;
}
.security-alert{
    background-color: #bb3e3e;
    flex: 1;
    & title{
        color: white;
    }
    & input{
        color: white;
        background-color: #bb3e3e;
        border-color: white;
    }
    & div div{
        border-color: white;
        & button div{
            & input{
                color: white;
                background-color: #bb3e3e;
                border-color: white;
            }
            & svg{
                color: #6acd9e;
            }
            & svg:active{
                color: #349d5e;
            }
        }
    } 
}
#download-human-log:active{
    background-color: #51e68d;
    color: black;
}
#security-record title{
    margin: auto;
    padding-top: 3rem;
    text-align: center;
}
#security-record{
    width: 100%;
}
#security-filter-row{
    width: 90%;
    margin: auto;
    margin-top: 2rem;
    & div{
        height: 100%;
        width: 80%;
        display:inline-block;
        vertical-align:bottom;
        & div{
            width: 50%;
            & button div{
                width: 80%;
            }
        }
    }
    & a{
        margin-left: 1%;
        width: 19%;
        display:inline-block;
        vertical-align:middle;
        text-align: center;
        border: solid;
        border-color: #51e68d;
        text-decoration: none;
        color: black;
        border-radius: 10%;
        height: 3.8rem;
        line-height: 3.5rem;
    }
} 
#date-input-div{
    margin: auto;
    width: 50%;
    margin-top: 1rem;
    & input{
        text-align: center;
        width: 100%;
    }
}
#screen{
    margin-top: 0.9rem;
    margin-bottom: 1rem;
    width: 100%;
}
@media screen and (max-width: 576px){
    #screen #alert-buttons{
        width: 100%;
        margin: none;
    }
    #security-filter-row{
        width: 100%;
        & div{
            width: 100%;
            display: block;
            & div{
                width: 50%;
                display: inline-block;
                & button div{
                    width: 80%;
                }
            }
        }
        & a{
            margin-top: 1rem;
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    }
}
#human-stream-video{
    max-width: 100%;
    max-height: 80vh;
    width: 100%;
    height: 80vh;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    font-size: 40px;
}
#security-alert{
    width: 100%;
}
#security-no-alert{
    width: 100%;
}
#sound-on-icon{
    & svg{
        font-size: 70px;
        color: #c3c43f;
        margin-left: auto;
        margin-right: auto;
    }
}
#sound-off-icon{
    & svg{
        font-size: 70px;
        color: #909090;
        margin-left: auto;
        margin-right: auto;
    }
}