@import "bootstrap/scss/bootstrap";

.border-0{
    border-width: 0 !important;
}

.border-1{
    border-width: ($spacer * .1) !important;
}

.border-2{
    border-width: ($spacer * .25) !important;
}

.border-3{
    border-width: ($spacer * .5) !important;
}

.border-4{
    border-width: ($spacer) !important;
}

.border-5{
    border-width: ($spacer * 1.5) !important;
}