
.header-lower-border {
    width: 100%;
    display: flex;
    height: 55px;
}

.header-lower-border-login {
    width: 100%;
}
.header-wrapper{
    display:flex;
    width: 100%;
}
.logo-container{
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
}
.xl-logo-column{
    position: relative;
    width: 100%;
    margin-right: auto;
}
.lg-logo-column{
    position: relative;
    width: 100%;
    margin-right: auto;
}
.sm-md-logo-column{
    position: relative;
    width: 100%;
    margin-right: auto;
}
.xs-logo-column{
    position: relative;
    width: auto;
    float: left;
    vertical-align: middle;
    margin-right: auto;
}
.xxs-logo-column{
    position: relative;
    vertical-align: middle;
    & div{
        margin-left:auto;
        margin-right:auto;
        width: 50px;
    }
}
.xl-lg-welcome-column{
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    position: absolute;
    overflow:visible;
    text-overflow:ellipsis;
    width:33%;
    text-align: center;
    white-space:nowrap;
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: center;
}
.header-icons{
    position: absolute;
    float: right;
    display: flex;
    vertical-align: middle;
    right:0;
}
.header-buttons{
    margin-top: -1rem;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    font-size: 40px;
    & svg{
        color: #4b7bc3;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
    & :active{
        color: #2a4c7e;
    }
}
.change-password-hyperlink{
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    text-decoration: underline;
}
.change-password-hyperlink:hover{
    cursor: pointer;
    color: #2d75e1;
}
.change-password-hyperlink:active{
    color: #154893;
}
.initials{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    background: #9549ad;
    vertical-align: middle;
    font-size: 20px;
    line-height: 45px;
}
.hkferry-logo{
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    margin-left: 0.25rem;
}
.welcome-title{
    vertical-align: middle;
    height: 55px;
    line-height: 55px;
    font-size: 20px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.settings-dropdown-list{
    list-style-type:none;
    text-align: center;
    font-size: 20px;
}
.settings-dropdown-item{
    color: black;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.settings-dropdown-item:active{
    color:#184267;
}
.settings-dropdown-container{
    background-color: #ededed;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom:0.5px;
}
#change-password-form{
    margin-bottom: -1rem;
    & p{
        font-size: 20px;
        font-style: italic;
    }
    & div{
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        border-bottom: solid;
        border-width: 0.1rem;
        font-size: 20px;
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns:
        [column1-start]
        73px
        [column1-end column2-start]
        10px
        [column2-end column3-start]
        calc(100% - 83px)
        [column3-end];
        position: relative;
        & label{
            grid-column-start:1;
            grid-column-end:2;
            display: grid;
            grid-template-columns:
            [column1-start]
            73px
            [column1-end column2-start]
            10px
            [column2-end];
            & p{
                font-style: normal;
            }
            & .password-label{
                grid-column-start:1;
                grid-column-end:1;
            }
            & .password-colon{
                grid-column-start:2;
                grid-column-end:2;
            }
        }
        & input{
            border-left: none;
            border-right: none;
            border-top: none;
            border-bottom: none;
            margin-left: 0.5rem;
            margin-top: -1rem;
            grid-column-start: 3;
            grid-column-end: 3;
            background-color: none;
            padding-right: 2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            & ::placeholder{
                font-style: italic;
            }
        }
        & button{
            position: absolute;
            right: 0.5rem;
        }
    }
}
.password-submit-button{
    background-color: #2d75e1;
    border: none;
    color: white;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 6rem;
    font-size: 120%;
    
}
.password-submit-button:active{
    background-color: #184267;
}
.password-close-button{
    background-color: black;
    border:none;
    color: white;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 6rem;
    font-size: 120%;
}
.password-close-button:active{
    background-color: #184267;
}
#root{
    display: flex;
    min-height: calc(100vh - 0rem);
    flex-direction: column;
}
main{
    flex: 1;
    display: flex;
    flex-direction: column;
    // min-height: 82vh;
}
#copyright-footer{
    background-color: #c2d2eb;
    color: rgba(61, 61, 61, 0.7);
    padding-top: 0.5rem;
    padding-left: 2rem;
    padding-bottom: 0.25rem;
    padding-right: 2rem;
    letter-spacing: .15rem;
    & p{
        margin-top: 0.125rem;
        margin-bottom: 0.125rem;
    }
}

.show-password-eye svg{
    color: black;
}
.show-password-eye-active svg{
    color: #c3c43f;
}