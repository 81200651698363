#job-create-wrapper{
    margin-left: 1rem;
    margin-right: 1rem;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
#job-create-top-row{
    overflow: hidden;
    padding-top: 1rem;
}
#document-top-row{
    overflow: hidden;
    padding-top: 1rem;
}
#finish-buttons{
    float: right;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    & button{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        margin-top: 0.75rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        font-size: 20px;
        vertical-align: middle;
        text-decoration: none;
        border-radius: 10%;
        border: none;
    }
}
#text-create-button{
    background-color: #c3c43f;
    color: black;
}
#text-create-button:active{
    background-color: #89a518;
}
#text-back-button{
    background-color: black;
    color: white;
}
#text-back-button:active{
    background-color: #3d3d3d;
}
#search-customer-and-vessel{
    float: left;
    display: flex;
    justify-content: space-between;
    width: 70%;
    & h3{
        margin-top: 0.5rem;
        margin-right: 1rem;
        margin-left: 1rem;
    }
    & form{
        display: flex;
        width: 80%;
        & .search-customer-and-vessel-input-container{
            width: calc(50% - 24px);
            &div{
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 768px){
    #search-customer-and-vessel{
        margin-top: 5rem;
        float: none;
        display: block;
        justify-content: space-between;
        width: 100%;
        & h3{
            width: 100%;
            margin-top: 0.5rem;
            display: flex;
            font-size: xx-large;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
        & form{
            display: block;
            width: 100%;
            & .search-customer-and-vessel-input-container{
                width: 100%;
            }
            & .search-button{
                margin-top: 0.3rem;
                float: right;
            }
        }
    }
}
#search-document{
    float: left;
    display: flex;
    justify-content: space-between;
    width: 40%;
    & h3{
        margin-top: 0.5rem;
        margin-right: 1rem;
        margin-left: 1rem;
    }
    & form{
        display: flex;
        width: 80%;
        & .search-document-input-container{
            width: calc(100% - 24px);
            &div{
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 768px){
    #search-document{
        margin-top: 5rem;
        float: none;
        display: block;
        justify-content: space-between;
        width: 100%;
        & h3{
            width: 100%;
            margin-top: 0.5rem;
            display: flex;
            font-size: xx-large;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
        & form{
            display: block;
            width: 100%;
            & .search-document-input-container{
                width: 100%;
            }
            & .search-button{
                margin-top: 0.3rem;
                float: right;
            }
        }
    }
}
#search-username-and-roles{
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 2rem;
    display: flex;
    justify-content: space-between;
    width: 90%;
    & h3{
        margin-top: 0.5rem;
        margin-right: 1rem;
    }
    & form{
        display: flex;
        width: 80%;
        & .user-search-field{
            width: 40%;
            & button{
                width: 100%;
                border: none;
                padding: 0;
                & div{
                    position: relative;
                    & input{
                        width: 100%;
                        margin: 0;
                        height: 3.5rem;
                        padding-bottom: 0.2rem;
                        font-size: 17px;
                        padding-left: 0.8rem;
                        line-height: 1rem;
                    }
                    & svg{
                        position: absolute;
                        right: 2px;
                        @extend .caret-icon;
                    }
                    & :active svg{
                        color:#6acd92;
                    }
                }
            }
        }
        & .search-button{
            margin-left: 0.2rem;
        }
    }
}
#create-job-main{
    margin-top: 3rem;
    width: 100%;
}
#create-job-header{
    text-align: center;
    margin-bottom: 1rem;
}
@media screen and (max-width: 576px){
    #search-username-and-roles{
        margin-top: 1rem;
        margin-right: 1rem;
        margin-left: 1rem;
        display: block;
        justify-content: space-between;
        width: 95%;
        & h3{
            margin-top: 0.5rem;
            display: flex;
            font-size: xx-large;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
        & form{
            display: block;
            width: 95%;
            & div{
                width: 100%;
            }
            & .user-search-field{
                width: 100%;
                & button{
                    width: 100%;
                    border: none;
                    padding: 0;
                    & div{
                        position: relative;
                        & input{
                            width: 100%;
                            margin: 0;
                            height: 3.5rem;
                            padding-bottom: 0.2rem;
                            font-size: 17px;
                            padding-left: 0.8rem;
                            line-height: 1rem;
                        }
                    }
                }
            }
            & .search-button{
                margin-top: 0.3rem;
                float: right;
            }
        }
    }
}
#search-customer-name{
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 2rem;
    display: flex;
    justify-content: space-between;
    width: 70%;
    & h3{
        margin-top: 0.5rem;
        margin-right: 1rem;
    }
    & form{
        display: flex;
        width: 80%;
        & div{
            width: 100%;
        }
    }
    & .search-button{
        margin-left: 0.2rem;
    }
}
@media screen and (max-width: 460px){
    #search-customer-name{
        margin-top: 1rem;
        margin-right: 1rem;
        margin-left: 0rem;
        display: block;
        justify-content: space-between;
        width: 100%;
        & h3{
            margin-top: 0.5rem;
            display: flex;
            font-size: xx-large;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
        & form{
            margin-top: 1rem;
            margin-left: 2rem;
            display: flex;
            width: 80%;
            & div{
                width: 100%;
            }
        }
        & .search-button{
            margin-top: 0.3rem;
            float: right;
            margin-right: 10%;
        }
    }
}
.buttons-with-borders{
    background: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    height: auto;
}
.buttons-without-borders{
    @extend .buttons-with-borders;
    border: none;
}
.caret-icon{
    color: #349d52;
}
.caret-icon:active{
    color: #6acd92;
}
.MuiMenuItem-root p{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.dual-fields-container{
    width: 96%;
    display: grid;
    grid-template-columns:
    [column1-start]
    120px
    [column1-end column2-start]
    2%
    [column2-end column3-start]
    auto
    [column3-end column4-start]
    2%
    [column4-end column5-start]
    120px
    [column5-end column6-start]
    2%
    [column6-end column7-start]
    auto
    [column7-end column8-start]
    2%
    [column8-end column9-start]
    0px
    [column9-end];
    margin-left: 4%;
    & div{
        margin-top: 2rem;
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 20px;
        display: grid;
        // position: relative;
        grid-template-columns:
        [column1-start]
        120px
        [column1-end column2-start]
        4.5%
        [column2-end column3-start]
        auto
        [column3-end];
        & .left-field-alert-text{
            font-size: 15px;
            grid-column-start: 3;
            grid-column-end: 4;
            margin-bottom: -1.5rem;
            overflow:visible;
            color: #bb3e3e;
        }
        & .normal-label{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow:hidden;
            height: 4rem;
            vertical-align: middle;
        }
        & textarea, & input, & button, div{
            display:inline-block;
            margin-top: 0rem;
            grid-column-start: 3;
            grid-column-end: 4;
        }
        & .normal-input{
            overflow: hidden;
            text-overflow: ellipsis;
            height: 4rem;
            line-height: 4rem;
        }
        & div div input{
            overflow: hidden;
            text-overflow: ellipsis;
        }
        & textarea{
            line-height: 2rem;
            padding-top: 0.95rem;
        }
        & input, & textarea, &button{
            width: 100%;
        }
        & input, & textarea{
            padding-left: 0.5rem;
        }
        & .password-input{
            padding-right: calc(3rem + 3%);
        }
        & .show-password-eye, & .show-password-eye-active{
            position: absolute;
            right: 4%;
            bottom: 3rem;
            font-size: xx-large;
        }
        & .show-password-eye svg{
            color: black;
        }
        & .show-password-eye-active svg{
            color: #c3c43f;
        }
        & button{
            @extend .buttons-without-borders;
            position: relative;
            & svg{
                position: absolute;
                right: 2px;
                @extend .caret-icon;
            }
            & :active svg{
                color: #6acd92;
            }
            & div{
                display:block;
                margin-top: 0rem;
            }
        }
    }
    .autocomplete-field div{
        overflow: hidden;
        text-overflow: ellipsis;
        & div div input{
            width:100%;
        }
        // height: 4rem;
        // line-height: 4rem;
    }
    & .left-field{
        grid-column-start: 1;
        grid-column-end: 4;
        height: 4rem;
    }
    & .right-field{
        grid-column-start: 5;
        grid-column-end: 8;
        height: 4rem;
    }
    & .long-field{
        grid-column-start: 1;
        grid-column-end: 8;
        display: grid;
        grid-template-columns: 
        [column1-start] 
        120px 
        [column1-end column2-start] 
        2% 
        [column2-end column3-start] 
        auto 
        [column3-end];
    }
    & .short-label{
        line-height: 4rem;
    }
    & .next-line-field{
        margin-top: 0rem;
    }
    & .left-alert-text-container{
        margin-bottom: -2rem;
        margin-top: 0rem;
    }
}
#ship-name-input{
    width: 100%
}
#ship-reg-no-input{
    width: 100%
}
#add-user-form{
    margin-top: 6rem;
}
#edit-user-form{
    margin-top: 6rem;
}
@media screen and (max-width: 1260px){
    .dual-fields-container div textarea{
        height: 8rem;
    }
}
@media screen and (max-width: 884px){
    .dual-fields-container div textarea{
        height: 10rem;
    }
}
#contact-person{
    margin-top: 4rem;
    margin-bottom: 3rem;
    & h3{
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-bottom: 1rem;
    }
    & div{
        margin-top: 1.5rem;
        padding-bottom: 0rem;
        & div{
            margin-top: 1rem;
            padding-bottom:0rem;
        }
    }
}
@media screen and (max-width: 768px){
    .dual-fields-container{
        width: 95%;
        display: block;
        margin-left: 4%;
        margin-right: 4%;
    }
    #upload-documents-form{
        margin-top: 8rem;
        margin-right: 1rem;
    }
    #create-job-main{
        margin-top: 1rem;
    }
    .view-job-form, .edit-job-form{
        margin-top: 8rem;
        margin-right: 1rem;
    }
    #add-user-form{
        margin-top: 8rem;
        margin-right: 1rem;
    }
    #edit-user-form{
        margin-top: 8rem;
        margin-right: 1rem;
    }
    #add-edit-customer-form{
        margin-top: 8rem;
        margin-right: 1rem;
    }
    #contact-person div{
        margin-bottom: 2rem;
    }
}
@media screen and (max-width: 396px){
    .edit-job-form{
        margin-top: 10rem;
    }
}
@media screen and (max-width: 680px){
    .dual-fields-container div textarea{
        height: 12rem;
    }
}
@media screen and (max-width: 576px){
    .dual-fields-container div textarea{
        height: 14rem;
    }
}
@media screen and (max-width: 506px){
    .dual-fields-container div textarea{
        height: 16rem;
    }
}
@media screen and (max-width: 500px){
    #edit-user-form{
        margin-top: 10rem;
    }
}
@media screen and (max-width: 460px){
    .dual-fields-container div textarea{
        height: 18rem;
    }
}
@media screen and (max-width: 426px){
    .dual-fields-container div textarea{
        height: 20rem;
    }
}
@media screen and (max-width: 390px){
    .dual-fields-container div textarea{
        height: 22rem;
    }
}
@media screen and (max-width: 368px){
    .dual-fields-container div textarea{
        height: 24rem;
    }
}
@media screen and (max-width: 356px){
    .dual-fields-container div textarea{
        height: 26rem;
    }
}
@media screen and (max-width: 340px){
    #edit-user-form{
        margin-top: 13rem;
    }
}
@media screen and (max-width: 332px){
    .dual-fields-container div textarea{
        height: 28rem;
    }
}
@media screen and (max-width: 322px){
    .dual-fields-container div textarea{
        height: 30rem;
    }
}
.role-container{
    width: 100%;
    & div {
        & div{
            margin-bottom: 2rem;
            width:21%;
            margin-right: 4%;
            display:inline-block;
            position: relative;
            & .dropdown-role-button {
                width: 100%;
                & div{
                    width:100%;
                    margin-bottom: 0rem;
                }
            }
            & .admin-role-button, & .admin-role-icon{
                position: absolute;
                right: 1.5rem;
                top: 0.1rem;
                & svg{
                    top: 0rem;
                }
            }
            & .readonly-role-input{
                background-color: #ededed;
            }
        }
    }
}
@media screen and (max-width: 1200px){
    .role-container div div{
        width: 28%;
    }
}
@media screen and (max-width: 992px){
    .role-container div div{
        width: 45%;
    }
}
@media screen and (max-width: 768px){
    .role-container div div{
        width: 45%;
    }
}
@media screen and (max-width: 576px){
    .role-container div div{
        width: 100%;
    }
}
.readonly-field-non-label{
    background-color: #ededed;
}
.job-overview-table-main{
    text-align: center;
    table-layout: auto;
    width: 100%;
    margin-top: -0.75rem;
}
#edit-view-job-container{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 3rem;
}
.view-edit-job-title{
    text-align: center;
    margin-top: 2rem;
}
#view-edit-buttons-container{
    float: right;
    margin-right: 1rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    & button{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.125rem;
        padding-bottom: 0.25rem;
        margin-top: 0.75rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        font-size: 20px;
        vertical-align: middle;
        text-decoration: none;
        border-radius: 10%;
        border: none;
    }
}
.view-edit-job-buttons{
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 10%;
    font-size: 20px;
}
#text-download-button{
    background-color: #51e68d;
    color: black;
}
#text-download-button:active{
    background-color: #1ab759;
}
#text-delete-button{
    background-color: #db1f1f;
    color: white;
}
#text-delete-button:active{
    background-color:#811212;
}
#text-save-button{
    background-color: #2d75e1;
    color: white;
}
#text-save-button:active{
    background-color: #154893;
}
#text-reset-button{
    background-color: #ededed;
}
#text-reset-button:active{
    background-color: #b8b8b8;
}
#text-change-password-button{
    background-color: #b51fdb;
    color: white;
}
#text-change-password-button:active{
    background-color: #6b1281;
}
.ship-edit-row{
    height: 3rem;
    & td{
        & input{
            width: 85%;
            padding-left: 0.5rem;
            height: 3rem;
        }
        & .readonly-table-input{
            background-color: #ededed;
        }
    }
}
#dialog-link{
    color: #2d75e1;
}
#dialog-link:active{
    color: #154893;
}
#job-create-wrapper{
    margin-bottom: 3rem;
}
#preview-image{
    max-width: 95%;
    max-height:90vh;
}
.slider {
	overflow-y: hidden;

	transition-property: all;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(.62, .33, 0.44, .74);
}
.slider.closed {
	max-height: 0;
}
.slider.opened {
	max-height: 2100px;
}
.show-button-container{
    display: flex;
    justify-content: center;
    & button{
        height: 3rem;
        width: 3rem;
        margin-top: 1rem;
        margin-left: 1rem;
        & svg{
            font-size: 2rem;
        }
    }
    & .show-button{
        background-color: #4b7bc3;
        border-radius: 10%;
        & svg{
            color:#c3c43f;
        }
        & svg:active{
            color:#89a518;
        }
    }
    & .show-button:active{
        background-color:#2a4c7e;
        & svg{
            color:#89a518;
        }
        & svg:active{
            color:#89a518;
        }
    }
}
.hide-button-container{
    width: 100%;
    display: flex;
    justify-content: center;
    & button{
        height: 3rem;
        width: 3rem;
        margin-top: 1rem;
        margin-left: 1rem;
        & svg{
            font-size: 2rem;
        }
    }
    & .hide-button{
        background-color: #ededed;
        border-radius: 10%;
        & svg{
            color:black;
        }
        & svg:active{
            color:#3d3d3d;
        }
    }
    & .hide-button:active{
        background-color:#b8b8b8;
        & svg{
            color:#3d3d3d;
        }
        & svg:active{
            color:#3d3d3d;
        }
    }
}
#edit-view-job-container{
    margin-bottom:3rem;
}
#edit-document-view-container{
    margin-bottom:3rem;
}
#file-button-active svg{
    color:#c3c43f;
}
#folder-button-active svg{
    color:#c3c43f;
}
#directory-path{  font-size: 2rem;
    margin-bottom: -5.5rem;
    margin-top: 1rem;
    margin-left: 1rem;
    letter-spacing: .1rem;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}