.status-button-container{
    width: 24%;
}
.calendar-button-container{
    width: 38%;
}
.buttons-with-borders{
    background: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    height: auto;
    width: auto;
}
.buttons-without-borders{
    @extend .buttons-with-borders;
    border: none;
}
.menu-button-container{
    border-style: solid;
    border-width: 0.025rem;
    overflow: hidden;
    display: inline-block;
    & button{
        text-transform: none;
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        position: relative;
        @extend .buttons-without-borders;
        width: 100%;
        & svg{
            position: absolute;
            right: 0.5rem;
            @extend .caret-icon;
        }
        & :active svg{
            color:#6acd92;
        }
        & div{
            height: 100%;
            margin-left: 1rem;
            margin-right: 1rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            & p{
                margin-left: 0.5rem;
                display: inline-block;
                vertical-align:middle;
                text-overflow:ellipsis;
            }
            & input{
                width: 100%;
                border-style: none;
                text-overflow: ellipsis;
            }
        }
    }
}
.sort-button-active{
    @extend .buttons-without-borders;
    & svg{
        color: #6b1281;
        margin-top: -0.7rem;
    }
}
.sort-button-inactive{
    @extend .buttons-without-borders;
    & svg{
        color: #909090;
        margin-top: -0.7rem;
    }
}
#job-overview-entire-container{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
#filter-grid{
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: [column1-start] 2% [column1-end column2-start] 12% [column2-end column3-start] auto [column3-end column4-start] 10% [column4-end];
    & h3{
        line-height: 3.5rem;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }
    & #first-filter-row{
        white-space: nowrap;
        display: inline-block;
        align-items: stretch;
        width: 100%;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
        margin-top: -0.5rem;
    }
    & form{
        width: 100%;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
        height: 7.5rem;
        & div{
            width: 100%;
            & .filter-form-input{
                box-sizing: border-box;
                display: inline-block;
                width: 50%;
                height: 3.5rem;
                & div{
                    width: 100%;
                    height: 3.5rem;
                    & label{
                        padding-top: 0.1rem;
                    }
                    & div{
                        width: 100%;
                        padding-right: 0.5rem;
                        margin-right: 0rem;
                        height: 3.5rem;
                        & input{
                            width: 100%;
                            margin-right: 0rem;
                            padding-right: 0.5rem;
                            padding-top: 0rem;
                            height: 3rem;
                        }
                    }
                }
            }
            & input{
                box-sizing: border-box;
                padding-left: 1rem;
                padding-right: 1rem;
                width: 50%;
                height: 3rem;}
        }
    }
    & #job-filter-submit-container{
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 3;
        grid-row-end: 4;
        margin-top: -0.4rem;
        margin-left: 0.3rem;
    }
}
@media screen and (max-width: 576px) { 
    #filter-grid{
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 3rem;
        display:block;
        // display: grid;
        // grid-template-columns: [column1-start] 2% [column1-end column2-start] 12% [column2-end column3-start] auto [column3-end column4-start] 10% [column4-end];
        & h3{
            display: flex;
            font-size: xx-large;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            line-height: 3.5rem;
        }
        & #first-filter-row{
            white-space:normal;
            display:block;
            width: 100%;
            margin-top: 0.35rem;
            & .calendar-button-container{
                width: 100%;
                margin-top: -0.35rem;
            }
            & .status-button-container{
                width: 100%;
                margin-top: -0.35rem;
            }
        }
        & #second-filter-row{
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 3.5rem;
            & form{
                width: 100%;
                & .filter-form-input{
                    box-sizing: border-box;
                    display: inline-block;
                    width: 50%;
                    height: 3.5rem;
                    & div{
                        width: 100%;
                        height: 3.5rem;
                        & label{
                            padding-top: 0.1rem;
                        }
                        & div{
                            width: 100%;
                            padding-right: 0.5rem;
                            margin-right: 0rem;
                            height: 3.5rem;
                            & input{
                                width: 100%;
                                margin-right: 0rem;
                                padding-right: 0.5rem;
                                padding-top: 0rem;
                                height: 3rem;
                            }
                        }
                    }
                }
                & input{
                    box-sizing: border-box;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    width: 50%;
                    height: 3rem;}
            }
        }
        & #job-filter-submit-container{
            margin-top: 0rem;
            margin-left: 0.3rem;
            float: right;
        }
    }
}
.search-button{
    @extend .buttons-without-borders;
    & svg {
        margin-top: 0.25rem;
        color: #3d3d3d;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        font-size: 40px;
    }
    & :active{
        color: black;
    }
}
.full-table{
    margin-top: 5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-bottom: 4.3rem;
    width: calc(100vw - 2.5rem);
    & title{
        text-align: center;
        font-size: 50px;
    }
    & div {
        & hr{
            margin: 0;
            width: 100%;
            height: 0px;
            border-top: solid;
            display:inline-block;}
        & div{
            margin-top: -0.09rem;
            & div{
                margin-top: 1rem;
                & div{
                    margin-top: 0rem;
                }
            }
            & .page-setter{
                float: right;
                width: 300px;
                white-space: nowrap;
                display: flex;
                flex-direction: row;
                margin-right: -0.5rem;
                & div{
                    display: inline-block;
                    & form {
                        display: inline-block;
                        margin-right: 0.2rem;
                        & input{
                            display: inline-block;
                            width: 50px;
                            margin-left:0.2rem;
                            margin-right:0.2rem;
                        }
                    } 
                    & button{
                        @extend .buttons-without-borders;
                        display: inline-block;
                        & svg {
                            color: #4b7bc3;
                            font-size: 30px;
                            margin-bottom: 0.125rem;
                            &:active{
                                color: #2a4c7e;
                            }
                        }
                    }
                }
            }
        }
        & table{
            text-align: center;
            table-layout: auto;
            width: 100%;
            margin-top: -0.75rem;
            border-collapse: separate;
            border-spacing: 0rem;
            margin-top: -0.3vw;
            & thead tr{
                line-height: calc(2rem + 1.5vw);
                & th{
                    position: relative;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: calc(17px + 0.3vw);
                    min-width: 5px;
                    max-width: 100px;
                    & #column-button{
                        margin-top: 0.25rem;
                        @extend .buttons-without-borders;
                        height: 26px;
                        line-height: calc(2rem + 1.5vw);
                        & p{
                            display: inline-block;
                            vertical-align: middle;
                            font-size: calc(16px + 0.3vw);
                        }
                        & svg{
                            margin-bottom:1rem;
                            display: inline-block;
                            vertical-align: middle;
                            @extend .caret-icon;
                        }
                    }
                    & input{
                        top: min(calc(17px + 0.8vw), 60px);
                        display: inline-block;
                        text-overflow: ellipsis;
                    }
                    & label{
                        margin-left: 30%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: block;
                    }
                } 
            } 
            & tbody tr{
                line-height: calc(2rem + 1.5vw);
                & td{
                    min-width: 5px;
                    max-width: 100px;
                    height: 40px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    position: relative;
                    white-space: nowrap;
                    font-size: calc(16px + 0.3vw);
                    & a{
                        color: #4b7bc3;
                        &:active{
                            color: #2a4c7e;
                        }
                    }
                    & input{
                        top: min(calc(10px + 0.8vw), 60px);
                        text-overflow: ellipsis;
                        font-size: calc(16px + 0.3vw);
                    }
                    & label{
                        margin-left: 30%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: block;
                    }
                    & button svg{
                        font-size: calc(25px + 0.3vw);
                    }
                }
            }
        }
    }
}
#preview-page-turner{
    & div{
        display: inline-block;
        & button{
            @extend .buttons-without-borders;
            display: inline-block;
            & svg {
                color: #4b7bc3;
                font-size: 30px;
                margin-bottom: 0.125rem;
                &:active{
                    color: #2a4c7e;
                }
            }
        }
    }
}
.vessels-table div table{
    & thead tr{
        height: 5rem;
    }
    & tbody tr{
        line-height: 0;
        & td input{
            top: 0;
        }
    }
}
.documents-table div table{
    border-spacing: 0rem;
    & thead tr{
        height: 5rem;
    }
    & tbody tr{
        line-height: 0;
        & td input{
            top: 0;
        }
    }
}
#pre-table-buttons-wrapper{
    float: left;
    white-space: nowrap;
    display: block;
    & button{
        @extend .buttons-with-borders;
        display: inline-block;
        border-style: solid;
        border-width: 0.2rem;
        height: 2.1rem;
        padding-left: 0.25rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        padding-right: 0.25rem;
        vertical-align: middle;
        border-radius: 10%;
    }
    & .icon-button{
        border: none;
        & svg{
            font-size: 2rem;
        }
    }
}
#table-create-button{
    border-color: #c3c43f;
}
#table-create-button:active{
    background-color: #c3c43f;
}
#table-create-icon{
    color: #c3c43f;
}
#table-create-icon:active{
    color:#89a518;
}
#table-up-directory-icon{
    color: #b51fdb;
}
#table-up-directory-icon:active{
    color: #6b1281;
}
#table-download-button{
    border-color: #51e68d;
}
#table-download-button:active{
    background-color: #51e68d;
}
#table-add-role-button{
    border-color: #a61dc9;
    color: black;
}
#table-add-role-button:active{
    background-color: #a61dc9;
    color: white;
}
// table-up-directory-icon (color: #a61dc9)
#table-edit-button{
    border-color: #2d75e1;
    color: black;
}
#table-edit-button:active{
    background-color: #2d75e1;
    color: white;
}
#create-jobs{
    font-size: 15px;
    vertical-align: middle;
    color: black;
    text-decoration: none
}
#export-jobs{
    font-size: 15px;
    vertical-align: middle;
    color: black;
    text-decoration: none
}
.action-icons{
    font-size: 25px;
}
.three-dots{
    color: white;
    background-color: #4b7bc3;
    border-radius: 50%;
}
.three-dots:active{
    background-color: #2a4c7e;
}
.three-lines{
    color: #4b7bc3;
}
.three-lines:active{
    color: #2a4c7e;
}
.pencil-icon{
    color: #4b7bc3;
}
.pencil-icon:active{
    color: #2a4c7e;
}
.open-folder-icon{
    color: #4b7bc3;
}
.open-folder-icon:active{
    color: #2a4c7e;
}
.show-icon{
    color: black;
}
.show-icon:active{
    color: #c3c43f;
}
.cloud-icon{
    color: #4b7bc3;
}
.cloud-icon:active{
    color:#2a4c7e;
}
.delete-icon{
    color: #bb3e3e;
}
.delete-icon:active{
    color:#6f2525;
}
.caret-icon{
    color: #349d5e;
}
.caret-icon:active{
    color: #6acd9e;
}
#job-action-menu div ul li{
    vertical-align: middle;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    white-space: nowrap;
    min-height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    & button{
        min-height: 48px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 16px;
        padding-right: 16px;
        &:active{
            .pencil-icon{
                color: #184267;
            }
            .cloud-icon{
                color:#184267;
            }
            .delete-icon{
                color:#6f2525;
            }
        }
    }
}
.menu-text{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
#alert-dialog-description{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black;
    font-size:x-large;
}
#add-role-form-container{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black;
    font-size:large;
    margin-bottom: -1.125rem;
    display: grid;
    grid-template-columns:
    [column1-start]
    80px
    [column1-end column2-start]
    calc(100% - 73px)
    [column2-end];
    & input{
        margin-left: 0.25rem;
        width: 100%;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        font-size: large;
    }
}
#add-role-form-field{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black;
    font-size:x-large;
    margin-left: 1rem;
}
.alert-button{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: none;
    border-radius: 10%;
    width: 6rem;
    font-size:120%;
}
#alert-first-button{
    background-color: #2d75e1;
    color: white;
    &:active{
        background-color: #184267;
    }
}
#alert-second-button{
    background-color: black;
    color: white;
    &:active{
        background-color: #3d3d3d;
    }
}
.file-alert svg{
    color: #532060;
    font-size: 1.6rem;
}