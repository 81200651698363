title{
    font-size: 50px;
    display: block;
}

.hkferry-logo{
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
}
.login-title{
    font-size: 50px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.header-lower-border {
    width: 100%;
    display: inline-block;
}

.login-box{
    background-color: #c2d2eb;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 4rem;
}

.login-error{
    color: #db1f1f;
}
.login-form{
    background-color: #c2d2eb;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0.5vh;
    width: 93%;
}
.login-form-field{
    background-color: #c2d2eb;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    padding-top: 2vh;
    width: 99.5%;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: solid;
    border-width: 0.1rem; 
    margin-bottom: 0.1rem;
    font-size:x-large;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.login-form-field:focus{
    outline-color:#2d75e1;
}
.login-form-field::placeholder{
    font-style: italic;
}
.login-submit-wrapper{
    text-align: center;
}
.login-submit{
    width: 50%;
    height: 3rem;
    background-color: #2d75e1;
    color: white;
    border: none;
    margin-top: 10vh;
    margin-bottom: 4vh;
    font-size: x-large;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.login-submit:active{
    background-color: #154893;
}
.error-text{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
